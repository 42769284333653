/* eslint-disable import/no-mutable-exports */
const env = process.env.REACT_APP_ENV;
let envValues = {
  PORT: 80,
  REACT_APP_API_ENDPOINT: 'http://localhost:4075/v1',
  REACT_APP_API_FRONT_END: 'http://localhost:4075',
  REACT_APP_API_PERMISSION_DENIED: 'http://localhost:4075/permission',
  REACT_APP_SHOW_COOKIE_BOX: false,
  SOCKET_STATUS: 'OFF',
  // fhconfig
  FIREBASE_CONFIG: {
    apiKey: 'AIzaSyCUzH7UhVcd3d5M1xoZlFG_Kk94N5UGvOE',
    authDomain: 'fluent-health-f6a75.firebaseapp.com',
    databaseURL: 'https://fluent-health-f6a75-default-rtdb.firebaseio.com',
    projectId: 'fluent-health-f6a75',
    storageBucket: 'fluent-health-f6a75.appspot.com',
    messagingSenderId: '380752182969',
    appId: '1:380752182969:web:0c5a62496762f8b5b3a568',
    measurementId: 'G-9M1B2T61N8',
  },
  COLLECTIONURL: 'notifications',
};
switch (env) {
  case 'staging':
    envValues = {
      PORT: 80,
      REACT_APP_API_ENDPOINT: 'https://fluenthealth-api.iscriptsdemo.com/v1',
      REACT_APP_API_FRONT_END: 'https://manage-fluenthealth.iscriptsdemo.com/',
      REACT_APP_API_PERMISSION_DENIED: 'https://manage-fluenthealth.iscriptsdemo.com/permission',
      REACT_APP_SHOW_COOKIE_BOX: false,
      SOCKET_STATUS: 'OFF',
      FIREBASE_CONFIG: {
        apiKey: 'AIzaSyCUzH7UhVcd3d5M1xoZlFG_Kk94N5UGvOE',
        authDomain: 'fluent-health-f6a75.firebaseapp.com',
        databaseURL: 'https://fluent-health-f6a75-default-rtdb.firebaseio.com',
        projectId: 'fluent-health-f6a75',
        storageBucket: 'fluent-health-f6a75.appspot.com',
        messagingSenderId: '380752182969',
        appId: '1:380752182969:web:0c5a62496762f8b5b3a568',
        measurementId: 'G-9M1B2T61N8',
      },
      COLLECTIONURL: 'notifications',
    };
    break;
  case 'production':
    envValues = {
      PORT: 80,
      REACT_APP_API_ENDPOINT: 'https://bp-api.prod.com/v1',
      REACT_APP_API_FRONT_END: 'https://bp-api.prod.com',
      REACT_APP_API_PERMISSION_DENIED: 'https://bp-api.prod.com/permission',
      REACT_APP_SHOW_COOKIE_BOX: false,
      SOCKET_STATUS: 'OFF',
      FIREBASE_CONFIG: {
        apiKey: 'AIzaSyCUzH7UhVcd3d5M1xoZlFG_Kk94N5UGvOE',
        authDomain: 'fluent-health-f6a75.firebaseapp.com',
        databaseURL: 'https://fluent-health-f6a75-default-rtdb.firebaseio.com',
        projectId: 'fluent-health-f6a75',
        storageBucket: 'fluent-health-f6a75.appspot.com',
        messagingSenderId: '380752182969',
        appId: '1:380752182969:web:0c5a62496762f8b5b3a568',
        measurementId: 'G-9M1B2T61N8',
      },
      COLLECTIONURL: 'notifications',
    };
    break;
  case 'development':
    envValues = {
      PORT: 80,
      REACT_APP_API_ENDPOINT: 'http://localhost:4075/v1',
      REACT_APP_API_FRONT_END: 'http://localhost:4075',
      REACT_APP_API_PERMISSION_DENIED: 'http://localhost:3000/permission',
      REACT_APP_SHOW_COOKIE_BOX: false,
      SOCKET_STATUS: 'OFF',
      FIREBASE_CONFIG: {
        apiKey: 'AIzaSyCbPri9KqHOS16Q7JC_N10SdkZ6w8RsRt8',
        authDomain: 'flamingo-ff566.firebaseapp.com',
        databaseURL: 'https://flamingo-ff566-default-rtdb.firebaseio.com/',
        projectId: 'flamingo-ff566',
        storageBucket: 'flamingo-ff566.appspot.com',
        messagingSenderId: '808659627886',
        appId: '1:808659627886:web:736e95e6c5c2e74f989b7f',
        measurementId: 'G-1Z9MZH8CGE',
      },
      COLLECTIONURL: 'notifications',
    };
    break;
  default:
    envValues = {
      PORT: 80,
      REACT_APP_API_ENDPOINT: 'http://localhost:4075/v1',
      REACT_APP_API_FRONT_END: 'http://localhost:4075',
      REACT_APP_API_PERMISSION_DENIED: 'http://localhost:4075/permission',
      REACT_APP_SHOW_COOKIE_BOX: false,
      SOCKET_STATUS: 'OFF',
      FIREBASE_CONFIG: {
        apiKey: 'AIzaSyCUzH7UhVcd3d5M1xoZlFG_Kk94N5UGvOE',
        authDomain: 'fluent-health-f6a75.firebaseapp.com',
        databaseURL: 'https://fluent-health-f6a75-default-rtdb.firebaseio.com',
        projectId: 'fluent-health-f6a75',
        storageBucket: 'fluent-health-f6a75.appspot.com',
        messagingSenderId: '380752182969',
        appId: '1:380752182969:web:0c5a62496762f8b5b3a568',
        measurementId: 'G-9M1B2T61N8',
      },
      COLLECTIONURL: 'notifications',
    };
    break;
}

export default envValues;
