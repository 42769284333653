import React, { Suspense, lazy } from 'react';
import { SpinnerDotted } from 'spinners-react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Outerlayout from '../layouts/outerLayout';
import Innerlayout from '../layouts/innerLayout';
import { getCookies, removeCookies } from '../hooks/useCookies';
import Settings from '../pages/settings/settings';
// import ViewUsers from '../pages/users/viewUser';
import UserEdit from '../pages/users/editUser';
// import UserIndex from '../pages/users/index';
import UserCreate from '../pages/users/createUser';
import Preferences from '../pages/settings/preferences';
import CreateRoles from '../pages/roles/createRoles';
import RolesIndex from '../pages/roles/index';
import EditRoles from '../pages/roles/editRoles';
import SettingsApi from '../pages/settings/SettingsApiPage';
import SettingsLayout from '../pages/settings/settingsLayout';
import PageNotFound from '../pages/404/pageNotFound';
import PermissionDenied from '../pages/permission/permission';
import ProfileLayout from '../pages/profile/profileLayout';
import PrivacyPoilcy from '../pages/cms/privacypolicy';

const Login = lazy(() => import('../pages/logIn/logIn'));
const UserIndex = lazy(() => import('../pages/users/index'));
const UserVerification = lazy(() => import('../pages/verify/userVerfication'));
// const Register = lazy(() => import('../pages/register/register'));
const Forgetpassword = lazy(() => import('../pages/passwords/forgetPassword'));

const ResetPassword = lazy(() => import('../pages/passwords/resetPassword'));
const Dashboard = lazy(() => import('../pages/dashboard/dashboard'));
const ChatContainer = lazy(() => import('../pages/chat/chat'));
const HooksTest = lazy(() => import('../components/hooksFormTest'));
const CustomHooksTest = lazy(() => import('../hooks/customHooksTest'));
const MyForm = lazy(() => import('../components/PhoneNumberBox/PhoneNumberBox'));
const AgGridTable = lazy(() => import('../components/AgTable/AgGridTable'));
// const PageNotFound = lazy(() => import('../pages/404/pageNotFound'));
const ProfilePage = lazy(() => import('../pages/profile/profilepage'));
const ChangePassword = lazy(() => import('../pages/profile/changePassword'));
const ProfileEdit = lazy(() => import('../pages/profile/profileEdits'));
// const Faq = lazy(() => import('../cms/faq'));
const ViewEmployee = lazy(() => import('../pages/employer/detail'));
const EmployeeIndex = lazy(() => import('../pages/employer/index'));
// const EmployeeCreate = lazy(() => import('../pages/employer/create'));
const EmployeeEdit = lazy(() => import('../pages/employer/edit'));
const AddEmployer = lazy(() => import('../pages/employer/create'));
const EmployeeRenewSubscription = lazy(() => import('../pages/employer/renewsubscription'));
const ViewRole = lazy(() => import('../pages/roles/view'));
const ArticlesIndex = lazy(() => import('../pages/articles/index'));
const ArticlesCreate = lazy(() => import('../pages/articles/create'));
const ArticleView = lazy(() => import('../pages/articles/view'));
const ArticlesEditContent = lazy(() => import('../pages/articles/edit'));
// const ViewArticles = lazy(() => import('../pages/articles/view'));
// const ExerciseDetails = lazy(() => import('../pages/exercises/details'));
// const ExerciseEdit = lazy(() => import('../pages/exercises/exerciseEdit'));
// const AddArticle = lazy(() => import('../pages/articles/add'));
const UserDetails = lazy(() => import('../pages/users/details'));
const EmployeeDetails = lazy(() => import('../pages/users/viewUser'));
// const ExercisePrograms = lazy(() => import('../pages/exercises/programs'));
const ResetPass = lazy(() => import('../pages/passwords/ResetPass'));
const SubscriptionPlan = lazy(() => import('../pages/subscriptionPlan/subscriptionPlan'));
const EmployerDetail = lazy(() => import('../pages/employer/detail'));
const ExerciseFeedbacks = lazy(() => import('../pages/exercisefeedbacks/detail'));
const PhysiotherapistsDetail = lazy(() => import('../pages/physiotherapists/details'));
const AddPhysiotherapist = lazy(() => import('../pages/physiotherapists/add'));
const ExercisesIndex = lazy(() => import('../pages/exercises/index'));
const ExercisesCreate = lazy(() => import('../pages/exercises/create'));
const ExercisesEdit = lazy(() => import('../pages/exercises/exerciseEdit'));
const ViewExercise = lazy(() => import('../pages/exercises/view'));
const NotificationsIndex = lazy(() => import('../pages/notifications/index'));
const ExerciseFeedbacksIndex = lazy(() => import('../pages/exercisefeedbacks/index'));
const ReviewRequestsIndex = lazy(() => import('../pages/reviewrequests/index'));
// const ViewReviewRequest = lazy(() => import('../pages/reviewrequests/view'));
const ViewSessionDetails = lazy(() => import('../pages/reviewrequests/sessiondetails'));
const ReviewRequestEdit = lazy(() => import('../pages/reviewrequests/edit'));
const SupportRequestIndex = lazy(() => import('../pages/supportrequests/index'));
const ViewSupportRequest = lazy(() => import('../pages/supportrequests/view'));
const ViewPhysiotherapist = lazy(() => import('../pages/physiotherapists/details'));
const PhysiotherapistIndex = lazy(() => import('../pages/physiotherapists/index'));
const PhysiotherapistEdit = lazy(() => import('../pages/physiotherapists/edit'));
const PhysiotherapistCreate = lazy(() => import('../pages/physiotherapists/add'));
const PrivacyPolicy = lazy(() => import('../pages/cms/privacypolicy'));
const Termsandconditions = lazy(() => import('../pages/cms/termsandconditions'));

export default function Routers() {
  return (
    <Router>
      <Routes>
        <Route element={<Outerlayout />}>
          <Route
            exact
            path="/"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        left: '50%',
                        position: 'absolute',
                        textAlign: 'center',
                        top: '50%',
                        color: '#39979d',
                      }}
                    />
                  </div>
                }
              >
                <UnProtectedRoute>
                  {' '}
                  <Login />
                </UnProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/login"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        left: '50%',
                        position: 'absolute',
                        textAlign: 'center',
                        top: '50%',
                        color: '#39979d',
                      }}
                    />
                  </div>
                }
              >
                <UnProtectedRoute>
                  {' '}
                  <Login />
                </UnProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/verify-email"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <UnProtectedRoute>
                  {' '}
                  <UserVerification />
                </UnProtectedRoute>
              </Suspense>
            }
          />
          {/* <Route
            exact
            path="/register"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        left: '50%',
                        position: 'absolute',
                        textAlign: 'center',
                        top: '50%',
                        color: '#39979d',
                      }}
                    />
                  </div>
                }
              >
                <UnProtectedRoute>
                  <Register />
                </UnProtectedRoute>
              </Suspense>
            }
          /> */}
          <Route
            exact
            path="/forgetpassword"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        left: '50%',
                        position: 'absolute',
                        textAlign: 'center',
                        top: '50%',
                        color: '#39979d',
                      }}
                    />
                  </div>
                }
              >
                <UnProtectedRoute>
                  <Forgetpassword />
                </UnProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/reset/:id"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <UnProtectedRoute>
                  {' '}
                  <ResetPassword />
                </UnProtectedRoute>
              </Suspense>
            }
          />
          {/* <Route exact path="/" element={<Dashboard />} /> */}
          <Route
            exact
            path="/reset"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <UnProtectedRoute>
                  {' '}
                  <ResetPassword />
                </UnProtectedRoute>
              </Suspense>
            }
          />
        </Route>
        <Route element={<Innerlayout />}>
          <Route
            exact
            path="/roles"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <RolesIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/messages"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ChatContainer />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/roles/create"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <CreateRoles />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/roles/edit/:roleId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <EditRoles />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/roles/viewdetails/:roleId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ViewRole />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/dashboard"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <Dashboard />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/employer"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <EmployeeIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />

          {/* <Route
            exact
            path="/employer/create"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <EmployeeCreate />
                </ProtectedRoute>
              </Suspense>
            }
          /> */}
          <Route
            path="/employer/edit/:employerId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <EmployeeEdit />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/employer/renewsubscription/:employerId"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <EmployeeRenewSubscription />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/employee/viewdetails/:employerId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ViewEmployee />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/notifications"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <NotificationsIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />

          {/* ............css special codes.....starts..... */}
          {/* <Route
            exact
            path="/exercises/details"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ExerciseDetails />
                </ProtectedRoute>
              </Suspense>
            }
          /> */}
          <Route
            exact
            path="/passwords/ResetPass/:id"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ResetPass />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/subscriptionPlan/subscriptionPlan"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <SubscriptionPlan />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/employer/detail"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <EmployerDetail />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/physiotherapists/details"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <PhysiotherapistsDetail />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/physiotherapists/add"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <AddPhysiotherapist />
                </ProtectedRoute>
              </Suspense>
            }
          />
          {/* ............css special codes.....ends.... */}
          {/* reviewrequest........starts.......... */}
          <Route
            exact
            path="/reviewrequests"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ReviewRequestsIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          {/* <Route
            exact
            path="/exercises/exerciseEdit"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ExerciseEdit />
                  <ViewReviewRequest />
                </ProtectedRoute>
              </Suspense>
            }
          /> */}
          <Route
            exact
            path="/reviewrequests/edit/:reviewrequestId"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ReviewRequestEdit />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/reviewrequests/sessiondetails/:reviewrequestId"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ViewSessionDetails />
                </ProtectedRoute>
              </Suspense>
            }
          />
          {/* ............feedbacks......... */}
          <Route
            exact
            path="/exercisefeedbacks/viewdetails/:exercisefeedbackId"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ExerciseFeedbacks />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/exercisefeedbacks"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ExerciseFeedbacksIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          {/* ..............physioterapist starts */}
          <Route
            exact
            path="/physiotherapist"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <PhysiotherapistIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/physiotherapist/create"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <PhysiotherapistCreate />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/physiotherapist/viewdetails/:employerId"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ViewPhysiotherapist />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/physiotherapist/edit/:physiotherapistId"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <PhysiotherapistEdit />
                </ProtectedRoute>
              </Suspense>
            }
          />
          {/* ..............physioterapist ends */}
          {/* <Route
            exact
            path="/articles/add"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <AddArticle />
                </ProtectedRoute>
              </Suspense>
            }
          /> */}
          <Route
            exact
            path="/employer/create"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <AddEmployer />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/user/details/:userId"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <EmployeeDetails />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/users/viewUser"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <UserDetails />
                </ProtectedRoute>
              </Suspense>
            }
          />
          {/* <Route
            exact
            path="/exercises/programs"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ExercisePrograms />
                </ProtectedRoute>
              </Suspense>
            }
          /> */}

          {/* ............articles.......... */}
          <Route
            exact
            path="/articles"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ArticlesIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/articles/create"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ArticlesCreate />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            path="/articles/view/:articleId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ArticleView />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            path="/articles/editcontent/:articleId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ArticlesEditContent />
                </ProtectedRoute>
              </Suspense>
            }
          />
          {/* <Route
            exact
            path="/articles/viewdetails/:articleId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ViewArticles />
                </ProtectedRoute>
              </Suspense>
            }
          /> */}

          {/* .........exercise */}
          <Route
            exact
            path="/exerciselibrary"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ExercisesIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/exercise/create"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ExercisesCreate />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            path="/exercise/edit/:exerciseId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ExercisesEdit />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/exercise/viewdetails/:exerciseId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ViewExercise />
                </ProtectedRoute>
              </Suspense>
            }
          />

          {/* exercise end */}
          {/* supportrequest starts.... */}
          <Route
            exact
            path="/supportrequests"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <SupportRequestIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/supportrequests/edit/:supportrequestId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                {' '}
                <ProtectedRoute>
                  <ViewSupportRequest />
                </ProtectedRoute>
              </Suspense>
            }
          />
          {/* supportrequest ends.... */}
          <Route
            exact
            path="/user/viewdetails/:userId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  {/* <ViewUsers /> */}
                  <UserDetails />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/user/create"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <UserCreate />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/user/edit/:userId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <UserEdit />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/hooks"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <HooksTest />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/customhooks"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <CustomHooksTest />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/text"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <MyForm />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/table"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <AgGridTable />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/user"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <UserIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route element={<SettingsLayout />}>
            <Route
              exact
              path="/settings"
              element={
                <Suspense
                  fallback={
                    <div>
                      <SpinnerDotted
                        style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                      />
                    </div>
                  }
                >
                  <ProtectedRoute>
                    {' '}
                    <Settings />
                  </ProtectedRoute>
                </Suspense>
              }
            />
            <Route
              exact
              path="/preferences"
              element={
                <Suspense
                  fallback={
                    <div>
                      <SpinnerDotted
                        style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                      />
                    </div>
                  }
                >
                  <ProtectedRoute>
                    {' '}
                    <Preferences />
                  </ProtectedRoute>
                </Suspense>
              }
            />
            <Route
              exact
              path="/api"
              element={
                <Suspense
                  fallback={
                    <div>
                      <SpinnerDotted
                        style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                      />
                    </div>
                  }
                >
                  <ProtectedRoute>
                    {' '}
                    <SettingsApi />
                  </ProtectedRoute>
                </Suspense>
              }
            />
          </Route>
          <Route
            exact
            path="/cms/privacypolicy"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted style={{ marginTop: '250px', marginLeft: '300px' }} />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <PrivacyPoilcy />
                </ProtectedRoute>
              </Suspense>
            }
          />
          {/* ..............privacy policy....... */}
          <Route
            exact
            path="/privacypolicy"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <PrivacyPolicy />
                </ProtectedRoute>
              </Suspense>
            }
          />
          {/* .....terms and conditions........ */}
          <Route
            exact
            path="/privacypolicy"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <PrivacyPolicy />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/termsandconditions"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <Termsandconditions />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route element={<ProfileLayout />}>
            <Route
              exact
              path="/profile"
              element={
                <Suspense
                  fallback={
                    <div>
                      <SpinnerDotted
                        style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                      />
                    </div>
                  }
                >
                  <ProtectedRoute>
                    {' '}
                    <ProfilePage />
                  </ProtectedRoute>
                </Suspense>
              }
            />
            <Route
              exact
              path="/profile-edit"
              element={
                <Suspense
                  fallback={
                    <div>
                      <SpinnerDotted
                        style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                      />
                    </div>
                  }
                >
                  <ProtectedRoute>
                    {' '}
                    <ProfileEdit />
                  </ProtectedRoute>
                </Suspense>
              }
            />

            <Route
              exact
              path="/changePassword"
              element={
                <Suspense
                  fallback={
                    <div>
                      <SpinnerDotted
                        style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                      />
                    </div>
                  }
                >
                  <ProtectedRoute>
                    {' '}
                    <ChangePassword />
                  </ProtectedRoute>
                </Suspense>
              }
            />
          </Route>
        </Route>
        <Route
          exact
          path="*"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerDotted
                    style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                  />
                </div>
              }
            >
              {' '}
              <PageNotFound />
            </Suspense>
          }
        />
        <Route
          exact
          path="/permission"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerDotted
                    style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                  />
                </div>
              }
            >
              <ProtectedRoute>
                {' '}
                <PermissionDenied />
              </ProtectedRoute>
            </Suspense>
          }
        />
      </Routes>
    </Router>
  );
}

// eslint-disable-next-line react/prop-types
function ProtectedRoute({ children }) {
  if (document.cookie && getCookies('Token')) {
    if (getCookies('USERPERMISSION')) {
      return children;
    }
    removeCookies('Token');
    removeCookies('refreshToken');
    sessionStorage.setItem('cookiesCleared', true);
    return <Navigate to="/login" />;
  }
  sessionStorage.setItem('cookiesCleared', true);
  return <Navigate to="/login" />;
}

// eslint-disable-next-line react/prop-types
function UnProtectedRoute({ children }) {
  return getCookies('Token') ? <Navigate to="/dashboard" /> : children;
}
