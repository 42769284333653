/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState, useContext } from 'react';
import { Button, Image } from 'react-bootstrap';
// import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
// import { Label } from 'reactstrap';
import { toast } from 'react-toastify';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faArrowLeft } from '@fortawesome/fontawesome-free-solid';
import EmailInput from '../../components/EmailBox/EmailBox';
import TextInput from '../../components/TextField/TextField';
// import SelectBox from '../../components/SelectBox/SelectBox';
//  import PhoneNumberInput from '../../components/PhoneNumberBox/PhoneNumberBox';
import { Entry } from '../../redux/entrySlice';
import PasswordField from '../../components/PasswordField/PasswordField';
// import MultipleFileUpload from '../../components/MultipleFileUpload/MultipleFileUpload';
// import DatePickerComponent from '../../components/DatePicker/DatePicker';
import { TableContext } from '../../contexts/tableContext';
import styles from './createUser.module.scss';
import backImg from '../../assets/back_arrow.svg';
import { getCookies } from '../../hooks/useCookies';

function UserCreate() {
  const { dashboardStyle } = useContext(TableContext);
  const [submitting, setSubmitting] = useState(false);
  const { bodyStyle } = useContext(TableContext);
  const { formthemeStyle } = useContext(TableContext);
  const { setDashboardHeader } = useContext(TableContext);
  const { errormsgStyle } = useContext(TableContext);
  // const [roles, setRoles] = useState([]);
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [perror, setPerror] = useState(null);
  const userId = getCookies('USERID');
  useEffect(() => {
    window.scrollTo(0, 0);
    setDashboardHeader('Add User Details');
  }, []);
  const dispatch = useDispatch();
  const {
    // control,
    register,
    handleSubmit,
    // reset,
    formState: { errors },
  } = useForm();
  function onSubmitdata(data) {
    if (!data.password.match(/\d/) || !data.password.match(/[a-zA-Z]/)) {
      setPerror('Password must contain at least 1 letter and 1 number');
    } else if (data.name.trim().length === 0) {
      document.getElementById('name').focus();
    }
    setSubmitting(true);
    setError(null);
    const apiData = {
      name: data.name,
      email: data.email,
      password: data.password,
      phone: data.phone,
      createdBy: userId,
    };
    data.actionUrl = 'user';
    data.actionMethod = 'post';
    data.apiData = apiData;
    dispatch(Entry(data)).then((resp) => {
      setSubmitting(false);
      if (resp.payload.code === 200) {
        toast.success(
          <div>
            Account added Successfully
            <br />
            <p>{`New account for ${data.name} has been created `}</p>
          </div>
        );
        navigate(-1);
      } else if (resp.payload.code === 401 || resp.payload.code === 400) {
        toast.error(resp.payload.data);
      } else {
        setError('Something went wrong!');
      }
    });
  }
  return (
    <div className={styles[bodyStyle]}>
      <div className={styles[dashboardStyle]}>
        <div className={styles.exerciseCard}>
          <form className={styles.nml_form} onSubmit={handleSubmit(onSubmitdata)}>
            <div className="form_head">
              <div
                className={`${styles.arrowback} backBtn`}
                onClick={() => {
                  navigate(-1);
                }}
              >
                <Image className="mr-1" src={backImg} alt="Back Arrow" />
                Back
              </div>
              <Button className={`${styles.saveBtn}`} type="submit" disabled={submitting}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 0C5.38125 0 0 5.38125 0 12C0 18.6187 5.38125 24 12 24C18.6187 24 24 18.6187 24 12C24 5.38125 18.6187 0 12 0Z"
                    fill="#38A169"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.8032 7.95468C18.0938 8.24531 18.0938 8.72343 17.8032 9.01406L10.772 16.0453C10.6267 16.1906 10.4345 16.2656 10.2423 16.2656C10.0501 16.2656 9.85791 16.1906 9.7126 16.0453L6.19697 12.5297C5.90635 12.2391 5.90635 11.7609 6.19697 11.4703C6.4876 11.1797 6.96572 11.1797 7.25635 11.4703L10.2423 14.4562L16.7438 7.95468C17.0345 7.65937 17.5126 7.65937 17.8032 7.95468Z"
                    fill="white"
                  />
                </svg>
                {submitting ? 'Please wait..' : 'Create'}
              </Button>
            </div>

            <div className={`${styles[formthemeStyle]} form_container`} id={styles.addform}>
              <div className={styles.details_card_head}>
                <h1>Add Employee</h1>
              </div>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <TextInput
                      className={styles.inputbox}
                      classNamedark={styles.inputbox1}
                      labelstyle={styles.formlabel}
                      label="Name"
                      fieldName="name"
                      placeHolder="Enter Name"
                      register={register}
                      errors={errors}
                      isRequired
                      mandatory
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <EmailInput
                      className={styles.inputbox}
                      classNamedark={styles.inputbox1}
                      labelstyle={styles.formlabel}
                      label="Email"
                      placeHolder="Enter Email Address"
                      fieldName="email"
                      register={register}
                      errors={errors}
                      isRequired
                      mandatory
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <PasswordField
                      fieldName="password"
                      className={styles.inputbox}
                      classNamedark={styles.inputbox1}
                      labelstyle={styles.formlabel}
                      label="Password"
                      register={register}
                      errors={errors}
                      placeHolder="Enter Password"
                      isRequired
                      minimLength={8}
                      eyeiconstyle={styles.eyeicon}
                      mandatory
                    />
                    {perror && <p className={styles[errormsgStyle]}>{perror}</p>}
                  </div>
                </div>
                <br />
                {error && (
                  <h6 className={styles[errormsgStyle]} style={{ float: 'right' }}>
                    {error}
                  </h6>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default UserCreate;
